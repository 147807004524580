import React, { useState } from "react";
import {
  Divider,
  Box,
} from "@mui/material";

import DashboardAppBar from "../../components/DashboardAppBar";
import EnhancedTable from "../../components/tables/PatientTable";
import AddPatientDialog from "../../components/dialogForms/AddPatientDialog"
import DeletePatientDialog from "../../components/dialogForms/DeletePatientDialog"

export default function Dashboard() {
  const [isAddPatientDialogOpen, setIsAddPatientDialogOpen] = useState(false);
  const openAddPatientDialog = () => setIsAddPatientDialogOpen(true);
  const closeAddPatientDialog = () => setIsAddPatientDialogOpen(false);

  const [isDeletePatientDialogOpen, setIsDeletePatientDialogOpen] = useState(false);
  const [deletedPatientID, setDeletedPatientID] = useState("");
  const [deletedPatientString, setDeletedPatientString] = useState("");
  const openDeletePatientDialog = (selectedPatientID, selectedPatientString) => {
    setIsDeletePatientDialogOpen(true); 
    setDeletedPatientID(selectedPatientID); 
    setDeletedPatientString(selectedPatientString);
  }
  const closeDeletePatientDialog = () => {
    setIsDeletePatientDialogOpen(false); 
    setDeletedPatientID("");
    setDeletedPatientString("");
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <DashboardAppBar />
      
      <Box
        sx={{
          mt: "-200px",
          py: 7.5,
          width: '100%',
          display: "flex",
          justifyContent: "center",
        }}>
        <EnhancedTable onOpenAddPatient={openAddPatientDialog} onOpenDeletePatient={openDeletePatientDialog} />
      </Box>
      
      <Divider />

      <AddPatientDialog isOpen={isAddPatientDialogOpen} onClose={closeAddPatientDialog} />
      <DeletePatientDialog isOpen={isDeletePatientDialogOpen} onClose={closeDeletePatientDialog} patientID={deletedPatientID} patientString={deletedPatientString}/>

    </Box>
  );
}
