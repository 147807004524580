import { Typography, Box, Modal, Snackbar, CircularProgress, Alert } from "@mui/material";
import { Stage, Layer, Rect, Line, Circle } from "react-konva";
import React, { useRef, useState, useEffect } from "react";
import PDFHeader from "./PDFHeader";
import { useTranslation } from 'react-i18next';
import { ref, get, set } from 'firebase/database';
import { database } from '../../firebaseConfig';

import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

import logoImage from "../../assets/dicoptProLogo.png";

const AmslerGridPDF = ({
  isGenerating,
  stopGenerating,
  userUID,
  patientUID,
  testDate,
  gridData
}) => {

  const [patientData, setPatientData] = useState(null);

  const [isModalLoadingPDF, setIsModalLoadingPDF] = useState(false);
  const [letGeneratePDF, setLetGeneratePDF] = useState(false);

  const { t } = useTranslation();

  // region PDF Popup logic
  // Lógica para generar el PDF, primero abrimos el formulario para obtener los datos necesarios
  useEffect(() => {
    if (isGenerating) {
      setIsModalLoadingPDF(true);
      generatePDF();
    }
  }, [isGenerating]);
  // endregion

  // region PDF load data
  const fetchPatientData = async () => {
    // patient data is in realtime database with uid as key
    // Define la referencia a los datos que necesitas (ajusta la ruta según tu estructura)
    const dataRef = ref(database, `patients/${userUID}/${patientUID}`);

    // Espera a obtener los datos de Firebase
    const snapshot = await get(dataRef);

    // Verifica si existen los datos
    if (snapshot.exists()) {
      // Obtén el valor de los datos
      setPatientData(snapshot.val());
    } else {
      throw new Error("No data available for the patient");
    }
  };
  const generatePDF = async () => {
    try {
      await fetchPatientData();
      setLetGeneratePDF(true);
    } catch (error) {
      console.error(error.message)
      handleSnackbarOpen();
      setIsModalLoadingPDF(false);
      stopGenerating();
    }
  };
  // endregion

  // region PDF generation
  const amslerGridRef = useRef(null);
  const mainHeader = useRef(null);
  useEffect(() => {
    if (letGeneratePDF) {
      // Lógica para generar el PDF
      if (amslerGridRef.current) {
        setTimeout(async () => {
          try {
            const canvas1 = await html2canvas(amslerGridRef.current, { scale: 2, useCORS: true });
            const imgData1 = canvas1.toDataURL('image/png');

            // Crear un nuevo PDF
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
              compress: true,
            });

            pdf.html(mainHeader.current, {
              callback: function (doc) {
                doc.addImage(imgData1, 'PNG', 35, 45, 140, 140, "leftGrid", "FAST"); // Tamaño A4
                doc.addImage(logoImage, "PNG", 25, 280, 70, 9, "logo", "FAST");
                doc.save(`Rejilla de Amsler ${patientData.name} ${patientData.surname}.pdf`);
              },
              x: 0,
              y: 0,
              width: 210, // Ancho del contenido en mm
              windowWidth: 210 * 3.75 // Ancho de la ventana en px
            });
            // Guardar el PDF
            //pdf.save(`Informe_${uid}.pdf`);
          } catch (error) {
            console.error('Error al exportar las secciones a imágenes:', error);
          } finally {
            setLetGeneratePDF(false);
            setIsModalLoadingPDF(false);
            stopGenerating();
          }
        }, 100);
      }
    }
  }, [letGeneratePDF]);
  // endregion

  // region Snackbar logic
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const handleSnackbarOpen = () => {
    setSnackbarMessage("Error al descargar el documento. Si acabas de terminar la prueba, espera unos segundos y vuelve a intentarlo.");
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region Amsler Drawing
  const renderAmslerGridLines = () => {

    const escotomaLines = gridData?.scotomaHelp;
    if (!escotomaLines) {
      return null;
    }
    const lines = [];
    lines.push(
      <Line
        points={[0, 400, 400, 0]}
        stroke={gridData.lineColor}
        strokeWidth={1}
      />
    );
    lines.push(
      <Line
        points={[0, 0, 400, 400]}
        stroke={gridData.lineColor}
        strokeWidth={1}
      />
    );
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  }

  // function to render the lines of the hess lancaster test
  const renderAmslerGridBoxes = () => {

    // check if hessLancasterStatus is null, if so, return null
    const matrixData = gridData?.matrix;
    if (!matrixData) {
      return null;
    }

    // loop to create the rest of the lines and store them in an array
    const rects = [];
    // get the number of elements in the matrix
    const n = matrixData.length;
    for (let i = 0; i < n; i++) {
      // values in matrix are int where the two first digits are the column (y) and the third and forth are the row (x)
      const x = matrixData[i] % 100;
      const y = Math.floor(matrixData[i] / 100);
      rects.push(
        <Rect
          x={x * 20 + 1}
          y={y * 20 + 1}
          width={19}
          height={19}
          fill={gridData.fillerColor}
        />);
    }

    // return the lines, but Each child in a list should have a unique "key" prop.
    return rects.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };
  // endregion

  // region HTML
  return (
    <React.Fragment>
      {
        letGeneratePDF ?
          <React.Fragment>
            <div ref={mainHeader}>
              <PDFHeader testDate={testDate}/>
              <Typography variant="h5" sx={{ ml: 5 }}>
                Rejilla de Amsler
              </Typography>
            </div>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={450}
              height={450}
              ref={amslerGridRef}
              style={{
                backgroundColor: `${gridData.backgroundColor}`, // Fondo negro
              }}
            >
              <Box
                width={401}
                height={401}
                style={{
                  backgroundColor: `${gridData.backgroundColor}`, // Fondo negro
                  backgroundSize: "20px 20px",
                  backgroundImage: `linear-gradient(to right, ${gridData.lineColor} 1px, transparent 1px),linear-gradient(to bottom, ${gridData.lineColor} 1px, transparent 1px)`
                }}
              >
                <Stage width={401} height={401}>
                  <Layer>
                    <Circle
                      radius={2}
                      x={201}
                      y={201}
                      stroke={gridData.lineColor}
                      strokeWidth={4}
                    />
                    {renderAmslerGridBoxes()}
                    {renderAmslerGridLines()}
                  </Layer>
                </Stage>
              </Box>
            </Box>
          </React.Fragment>
          :
          null
      }
      {/* Loading PDF modal */}
      <Modal
        open={isModalLoadingPDF}
        onClose={() => { }}
        aria-labelledby="generating-pdf-title"
        aria-describedby="generating-pdf-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography
            id="generating-pdf-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2 }}
          >
            {t('pdfLocale.pdfModalTitle')}
          </Typography>
          <Typography id="generating-pdf-description" sx={{ mt: 2 }}>
            {t('pdfLocale.pdfModalDescription')}
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        sx={{
          marginLeft: 15,
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default AmslerGridPDF;