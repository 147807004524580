import React from "react";
import {
  Box
} from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function MainContent() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
    {/* Box with text indicating the user must update the app */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <h2>{t('updateApp')}</h2>
    </Box>
    </React.Fragment>
  );
}
