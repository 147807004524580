// PermissionsRoute.js
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../providers/AuthContext";
import { database, auth } from '../firebaseConfig'; // Asegúrate de importar tus configuraciones de Firebase
import { ref, child, get } from "firebase/database";
import { Navigate, useParams } from "react-router-dom";

const PermissionsRoute = ({ requiredPermission, children }) => {
  const { currentUser } = useContext(AuthContext);
  const [hasPermission, setHasPermission] = useState(null);
  const [loading, setLoading] = useState(true);
  const { uid } = useParams();
  const noPermissionURL = `/patient/${uid}/update`;
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = currentUser.uid;
        const dbRef = ref(database);
        get(child(dbRef, `permissions/${userId}`)).then((snapshot) => {
          if (snapshot.exists()) {
            const userPermissions = snapshot.val();
            setHasPermission(userPermissions && userPermissions[requiredPermission]);
          } else {
            setHasPermission(false);
          }
          setLoading(false);
        }).catch((error) => {
          console.error(error);
        });
      }
    };
    setLoading(true);
    fetchPermissions();
  }, [requiredPermission]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (!hasPermission) {
    return <Navigate to={noPermissionURL} replace/>;
  }
  else{
    return <>{children}</>;;
  }
};

export default PermissionsRoute;
