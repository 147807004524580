// src/providers/HeartbeatContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { ref, onValue, off, get } from 'firebase/database';
import { database } from '../firebaseConfig';

import { AuthContext } from "../providers/AuthContext";

const HeartbeatContext = createContext();

const HEARTBEAT_TIMEOUT = 10000;
const CHECK_INTERVAL = 20000;

const HeartbeatProvider = ({ children }) => {
  const [isAppAlive, setIsAppAlive] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {

    if (!currentUser) {
      setIsAppAlive(false);
      return;
    }

    const appHeartbeatRef = ref(database, `appStatus/${currentUser.uid}/lastHeartbeat`);
    let intervalId;
    let listenerActive = false;

    const startInterval = () => {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        checkHeartbeat();
      }, CHECK_INTERVAL);
    };

    const handleHeartbeatChange = (snapshot) => {
      const appLastHeartbeat = snapshot.val();
      if (appLastHeartbeat) {
        const isActive = (Date.now() - appLastHeartbeat) < HEARTBEAT_TIMEOUT;
        setIsAppAlive(isActive);
      } else {
        setIsAppAlive(false);
      }
      startInterval();
    };

    // si hemos llegado al límite de tiempo sin recibir un heartbeat, se considera que la app está inactiva o que ha habido un problema con la sincronización
    const checkHeartbeat = async () => {
      // vamos a comprobar aun asi la informacion de la base de datos, es mejor y nos ayuda en las pruebas.
      const appHeartbeatSnapshot = await get(appHeartbeatRef);
      const appLastHeartbeat = appHeartbeatSnapshot.val();
      if (appLastHeartbeat) {
        // en este caso, solo vamos a comprobar el pulso, si este ha vuelto, volvemos a activar el listener y que sea este el que compruebe el resto de datos
        const correctHeartbeat = (Date.now() - appLastHeartbeat) < HEARTBEAT_TIMEOUT;
        if (correctHeartbeat) {
          // si el listener no esta activo, lo activamos. Si está activo, no se hace nada
          if (!listenerActive){
            onValue(appHeartbeatRef, handleHeartbeatChange);
            listenerActive = true;
          }
        }
        // en el caso de que el pulso sea incorrecto, desactivamos el listener y marcamos la app como inactiva
        else{
          off(appHeartbeatRef, 'value', handleHeartbeatChange)
          listenerActive = false;
          setIsAppAlive(false);  
        }
      }
      // si no hay informacion, directamente desactivamos todo
      else{
        off(appHeartbeatRef, 'value', handleHeartbeatChange)
        listenerActive = false;
        setIsAppAlive(false);
      }
    };

    // Comprobar inmediatamente al montar el componente
    checkHeartbeat();

    // Inicializar el intervalo que comprueba el estado de la conexion
    startInterval();

    return () => {
      off(appHeartbeatRef, 'value', handleHeartbeatChange)
      listenerActive = false;
      setIsAppAlive(false);
      clearInterval(intervalId);
    };
  }, [currentUser]);

  return (
    <HeartbeatContext.Provider value={{ isAppAlive }}>
      {children}
    </HeartbeatContext.Provider>
  );
};

export { HeartbeatProvider, HeartbeatContext };
