import React, { useEffect, useState } from "react";
import axios from "axios";
import { Stage, Layer, Circle, Line, Text, Rect } from "react-konva";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";

const ChartComponent = ({ circleRadius, center }) => {
  const [eyePosition, setEyePosition] = useState({ x: 0, y: 0 });
  const { currentUser } = useContext(AuthContext);

  const getPositionX = (position) => {
    switch (position) {
      case "center":
        return 250;
      case "left":
        return 30;
      case "right":
        return 465;
      case "up":
        return 250;
      case "down":
        return 250;
      case "up_25_degrees":
        return 250; // Example positioning, adjust as needed
      case "down_25_degrees":
        return 250; // Example positioning, adjust as needed
      case "left_25_degrees":
        return 70; // Example positioning, adjust as needed
      case "right_25_degrees":
        return 425; // Example positioning, adjust as needed
      default:
        return 250;
    }
  };

  const getPositionY = (position) => {
    switch (position) {
      case "center":
        return 250;
      case "left":
        return 250;
      case "right":
        return 250;
      case "up":
        return 25;
      case "down":
        return 470;
      case "up_25_degrees":
        return 75; // Example positioning, adjust as needed
      case "down_25_degrees":
        return 435; // Example positioning, adjust as needed
      case "left_25_degrees":
        return 250; // Example positioning, adjust as needed
      case "right_25_degrees":
        return 250; // Example positioning, adjust as needed
      default:
        return 250;
    }
  };

  useEffect(() => {
    const fetchEyePosition = () => {
      const userId = currentUser.uid;
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/server/eye-position/${userId}`)
        .then((response) => {
          const { gaze_x, gaze_y } = response.data;
          // Convert from -1.25 to 1.25 range into 0 to 500 pixel range, inverting Y for gaze position
          const scaledGazeX = ((gaze_x + 1.25) / 2.5) * 500;
          const scaledGazeY = ((-gaze_y + 1.25) / 2.5) * 500;
          setEyePosition({ x: scaledGazeX, y: scaledGazeY });
        })
        .catch((error) => console.error("Error fetching eye position:", error));
    };

    fetchEyePosition();
    const intervalId = setInterval(fetchEyePosition, 200);

    return () => clearInterval(intervalId);
  }, [currentUser.uid]);

  const axisProps = {
    stroke: "grey",
    strokeWidth: 1,
    lineCap: "round",
  };

  const verticalAxisPoints = [250, 0, 250, 500];
  const horizontalAxisPoints = [0, 250, 500, 250];

  // Mapping function for numerical values to degrees
  const valueToDegrees = (value) => {
    switch (value) {
      case -1.25:
        return "30°";
      case -0.625:
        return "15°";
      case 0:
        return "";
      case 0.625:
        return "15°";
      case 1.25:
        return "30°";
      default:
        return `${value}°`;
    }
  };

  return (
    <Stage width={500} height={500}>
      <Layer>
        <Rect
          x={0}
          y={0}
          width={500}
          height={500}
          stroke="black"
          strokeWidth={2}
        />
        <Circle
          radius={circleRadius * 8}
          x={getPositionX(center)}
          y={getPositionY(center)}
          stroke="green"
          strokeWidth={2}
        />
        <Line {...axisProps} points={verticalAxisPoints} />
        <Line {...axisProps} points={horizontalAxisPoints} />

        {[-1.25, -0.625, 0, 0.625, 1.25].map((value) => {
          let xPos = 240 + value * 175;
          let yPos = 270 - ((value * 1.8) / 4) * 400;
          return (
            <React.Fragment key={value}>
              <Text
                x={xPos}
                y={260}
                text={valueToDegrees(value)}
                fontSize={12}
                fill="black"
              />
              <Text
                x={260}
                y={yPos - 23}
                text={valueToDegrees(value)}
                fontSize={12}
                fill="black"
              />
            </React.Fragment>
          );
        })}
        <Circle
          x={eyePosition.x}
          y={eyePosition.y}
          radius={4}
          fill="rgb(54, 162, 235)"
        />
        {/* Adding crosshair for the midpoint of the eyes */}
        <Line
          points={[
            getPositionX(center) - 10,
            getPositionY(center),
            getPositionX(center) + 10,
            getPositionY(center),
          ]}
          stroke="red"
          strokeWidth={2}
        />
        <Line
          points={[
            getPositionX(center),
            getPositionY(center) - 10,
            getPositionX(center),
            getPositionY(center) + 10,
          ]}
          stroke="red"
          strokeWidth={2}
        />
      </Layer>
    </Stage>
  );
};

export default ChartComponent;
