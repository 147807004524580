import React, { useState, useEffect } from 'react';
import './A4page.css'; // Asegúrate de importar los estilos
import CampimetryPDF from './CampimetryPDF';



const A4Page = () => {

  const [fileContent0, setFileContent0] = useState('');
  const [fileContent1, setFileContent1] = useState('');
  const [fileContent2, setFileContent2] = useState('');
  const [fileContent3, setFileContent3] = useState('');
  const [fileContent4, setFileContent4] = useState('');

  const [campimetryData, setCampimetryData] = useState({
    puntos: { error: true, img_src: '' },
    valores_cruz: { error: true, img_src: '' },
    valores_equis: { error: true, img_src: '' },
    interpolados_absoluto: { error: true, img_src: '' },
    interpolados_relativo: { error: true, img_src: '' },
    section_done: [false, false, false, false, false],
    contrast_pattern: ["", "", "", "", ""],
    eye_fixation_sensitivity: [0, 0, 0, 0, 0],
    fake_positives: [0, 0, 0, 0, 0],
    total_fake_positives: [0, 0, 0, 0, 0],
    fake_negatives: [0, 0, 0, 0, 0],
    total_fake_negatives: [0, 0, 0, 0, 0],
    eye_value: 0,
    date_value: "",
    total_time: 0
  });

  const patientData = {
    name: "Fernando",
    surname: "Rubio Perona",
    birthDate: "08/12/1990"
  };

  const formData = {
    gender: "Hombre",
    leftEye: { value: "1.0" },
    rightEye: { value: "0.9" },
    glasses: false
  };

  useEffect(() => {
    fetch('/TestImages/image0.txt')
      .then(response => response.text())
      .then(text => setFileContent0(text))
      .catch(error => console.error('Error al leer el archivo:', error));

    fetch('/TestImages/image1.txt')
      .then(response => response.text())
      .then(text => setFileContent1(text))
      .catch(error => console.error('Error al leer el archivo:', error));

    fetch('/TestImages/image2.txt')
      .then(response => response.text())
      .then(text => setFileContent2(text))
      .catch(error => console.error('Error al leer el archivo:', error));

    fetch('/TestImages/image3.txt')
      .then(response => response.text())
      .then(text => setFileContent3(text))
      .catch(error => console.error('Error al leer el archivo:', error));

    fetch('/TestImages/image4.txt')
      .then(response => response.text())
      .then(text => setFileContent4(text))
      .catch(error => console.error('Error al leer el archivo:', error));
  }, []);

  useEffect(() => {
    const campimetryAux = {};
    campimetryAux.puntos = { error: false, img_src: fileContent2 };
    campimetryAux.valores_cruz = { error: false, img_src: fileContent4 };
    campimetryAux.valores_equis = { error: false, img_src: fileContent3 };
    campimetryAux.interpolados_absoluto = { error: false, img_src: fileContent0 };
    campimetryAux.interpolados_relativo = { error: false, img_src: fileContent1 };

    campimetryAux.section_done = [true, true, true, true, true];
    campimetryAux.contrast_pattern = ["0-1", "0-1", "0-1", "0-1", "0-1"];
    campimetryAux.eye_fixation_sensitivity = [4, 4, 4, 4, 4];
    campimetryAux.fake_positives = [0, 1, 2, 3, 4];
    campimetryAux.total_fake_positives = [5, 5, 5, 5, 5];
    campimetryAux.fake_negatives = [0, 1, 2, 3, 4];
    campimetryAux.total_fake_negatives = [5, 5, 5, 5, 5];
    campimetryAux.eye_value = 2;
    campimetryAux.date_value = "18:04:12 - 10/06/2024";
    campimetryAux.total_time = 617.5;

    setCampimetryData(campimetryAux);

  }, [fileContent0, fileContent1, fileContent2, fileContent3, fileContent4]);

  return (
    <div className="a4-page">
      {CampimetryPDF({
        patientData: patientData,
        formData: formData,
        campimetryData: campimetryData
      })}
    </div>
  );
};

export default A4Page;