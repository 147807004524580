import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthContext";


const AuthenticatedRoute = () => {
  const { userLoggedIn } = useContext(AuthContext);

  if (!userLoggedIn) {
    return <Navigate to="/" replace/>;
  }
  else{
    return <Outlet/>;
  }
};

export default AuthenticatedRoute;