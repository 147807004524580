import { auth, database } from "../firebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { createContext, useState, useEffect } from "react";

import { ref, set } from 'firebase/database';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, initializeUser);
    return unsuscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }
    setLoading(false);
  }

  const login = (userData) => {
    // Logic to authenticate user
    setCurrentUser(userData);
    setUserLoggedIn(true);
  };

  const logout = () => {
    // Logic to log out user
    setCurrentUser(null);
    setUserLoggedIn(false);

    try {
      signOut(auth);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // when the start button is clicked, we need to update the database to start the test, in config_web, two variables are set: launchTest is true and launchTestReaded is false
  const updateLastTestDate = async (patientUid) => {
    if (patientUid == null || patientUid === '' || currentUser.uid == null || currentUser.uid === '') {
      return; // Terminar la ejecución de la función
    }
    const configRef = ref(database, `patients/${currentUser.uid}/${patientUid}/lastTestDate`);
    // Obtener la fecha actual
    let fechaActual = new Date();

    // Obtener el año, mes y día
    let año = fechaActual.getFullYear();
    let mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados, por lo que sumamos 1
    let día = String(fechaActual.getDate()).padStart(2, '0');

    // Formatear la fecha en YYYY-MM-DD
    let fechaFormateada = `${año}-${mes}-${día}`;
    await set(configRef,fechaFormateada);
  };

  const value = {
    currentUser,
    userLoggedIn,
    loading,
    login,
    logout,
    updateLastTestDate
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
