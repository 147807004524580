import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Stage, Layer, Line, Circle, Text } from "react-konva";
import { AuthContext } from "../../providers/AuthContext";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";

const ContrastSensitivity12 = () => {

  const [contrastSensitivityStatus, setContrastSensitivityStatus] = React.useState(null);
  const { currentUser } = useContext(AuthContext);
  const { uid } = useParams();

  const { t } = useTranslation();

  const cpd_1_5_values = [
    416.7692307692308,
    381.3076923076923,
    345.84615384615387,
    310.38461538461536,
    274.9230769230769,
    239.46153846153848,
    204.0,
    148.53846153846155,
    93.0769230769231
  ];

  const cpd_3_values = [
    406.7692307692308,
    360.51282051282055,
    310.38461538461536,
    288.33333333333337,
    239.46153846153848,
    204.0,
    204.0,
    148.53846153846155,
    93.0769230769231
  ];

  const cpd_6_values = [
    381.3076923076923,
    310.38461538461536,
    274.9230769230769,
    239.46153846153848,
    204.0,
    204.0,
    148.53846153846155,
    93.0769230769231,
    93.0769230769231
  ];

  const cpd_12_values = [
    416.7692307692308,
    381.3076923076923,
    345.84615384615387,
    310.38461538461536,
    274.9230769230769,
    239.46153846153848,
    204.0,
    148.53846153846155,
    93.0769230769231
  ];

  useEffect(() => {
    fetchContrastSensitivityStatus(); // Fetch immediately on mount
    const intervalId = setInterval(fetchContrastSensitivityStatus, 1000); // Adjust the interval as needed
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentUser.uid]); // Depend on uid so if it changes, we re-fetch

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchContrastSensitivityStatus = async () => {
    const userId = currentUser.uid;

    axios
      .get(`${SERVER_URL}/server/contrast-sensitivity-status/${userId}`)
      .then((response) => {
        setContrastSensitivityStatus(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the patient data:", error);
      });
  };

  const setYAxisLines = (yValues, yLabels, isLeft) => {
    // return the lines, but Each child in a list should have a unique "key" prop.
    const lines = [];

    // define new aux variable for lines
    let paddingLeftLine = 100;
    let paddingLeftText = 50;
    let alignText = "right";
    if (!isLeft) {
      paddingLeftLine = 510;
      paddingLeftText = 515;
      alignText = "left";
    }

    yValues.forEach((yValue, index) => {
      lines.push(
        <Line
          points={[paddingLeftLine, yValue, paddingLeftLine - 10, yValue]}
          stroke="black"
          strokeWidth={1}
        />
      );
      lines.push(
        <Text
          x={paddingLeftText}
          y={yValue - 8}
          width={35}
          text={yLabels[index]}
          fontSize={18}
          fontFamily="system-ui"
          align={alignText}
        />
      );
    });


    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const setXAxisLines = (xValue, xLabels) => {
    // return the lines, but Each child in a list should have a unique "key" prop.
    const lines = [];

    // define new aux variable for lines
    let paddingLeftLine = 510;
    let paddingLeftText = 520;
    let alignText = "center";

    xValue.forEach((xValue, index) => {
      lines.push(
        <Line
          points={[xValue, paddingLeftLine - 10, xValue, paddingLeftLine]}
          stroke="black"
          strokeWidth={1}
        />
      );
      lines.push(
        <Text
          x={xValue - 15}
          y={paddingLeftText}
          width={30}
          text={xLabels[index]}
          fontSize={18}
          fontFamily="system-ui"
          align={alignText}
        />
      );
    });

    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  const setDots = (xPos) => {
    const xVector = [150, 250, 350, 450];

    let actual_cpd_step = 0;
    let actual_y_values = [];

    console.log(contrastSensitivityStatus)

    // depending on xPos, we have to chose a cpd step
    if (xPos === 0) {
      // check cpd_1_5_step, and if it is null, set it to 0
      actual_cpd_step = contrastSensitivityStatus?.cpd_1_5_step || 0;
      actual_y_values = cpd_1_5_values;
    }
    else if (xPos === 1) {
      // check cpd_3_step, and if it is null, set it to 0
      actual_cpd_step = contrastSensitivityStatus?.cpd_3_step || 0;
      actual_y_values = cpd_3_values;
    }
    else if (xPos === 2) {
      // check cpd_6_step, and if it is null, set it to 0
      actual_cpd_step = contrastSensitivityStatus?.cpd_6_step || 0;
      actual_y_values = cpd_6_values;
    }
    else if (xPos === 3) {
      // check cpd_12_step, and if it is null, set it to 0
      actual_cpd_step = contrastSensitivityStatus?.cpd_12_step || 0;
      actual_y_values = cpd_12_values;
    }

    if (actual_cpd_step > 9) {
      actual_cpd_step = 9;
    }

    const dots = [];
    actual_y_values.forEach((yValue, index) => {
      let color = "grey";
      if (contrastSensitivityStatus?.actual_user === uid){
        if (index == (actual_cpd_step - 1)) {
          color = "#5DD55D"
        }
      }
      dots.push(
        <Circle
          x={xVector[xPos]}
          y={yValue}
          radius={5}
          fill={color}
        />
      );
    });

    return dots.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  // function to render the lines of the hess lancaster test
  const renderContrastSensitivityLines = () => {
    // check if hessLancasterStatus is null, if so, return null
    if (!contrastSensitivityStatus) {
      return null;
    }

    if(contrastSensitivityStatus?.actual_user !== uid){
      return null;
    }

    let CPD_1_5 = contrastSensitivityStatus?.cpd_1_5_step || 0;
    let CPD_3 = contrastSensitivityStatus?.cpd_3_step || 0;
    let CPD_6 = contrastSensitivityStatus?.cpd_6_step || 0;
    let CPD_12 = contrastSensitivityStatus?.cpd_12_step || 0;

    if (CPD_1_5 > 9) {
      CPD_1_5 = 9;
    }
    if (CPD_3 > 9) {
      CPD_3 = 9;
    }
    if (CPD_6 > 9) {
      CPD_6 = 9;
    }
    if (CPD_12 > 9) {
      CPD_12 = 9;
    }

    // loop to create the rest of the lines and store them in an array
    const lines = [];
    if (CPD_1_5 > 0 && CPD_3 > 0) {
      lines.push(
        <Line
          points={[150, cpd_1_5_values[CPD_1_5 - 1], 250, cpd_3_values[CPD_3 - 1]]}
          stroke="#5DD55D"
          strokeWidth={3}
        />
      );
    }
    if (CPD_3 > 0 && CPD_6 > 0) {
      lines.push(
        <Line
          points={[250, cpd_3_values[CPD_3 - 1], 350, cpd_6_values[CPD_6 - 1]]}
          stroke="#5DD55D"
          strokeWidth={3}
        />
      );
    }
    if (CPD_6 > 0 && CPD_12 > 0) {
      lines.push(
        <Line
          points={[350, cpd_6_values[CPD_6 - 1], 450, cpd_12_values[CPD_12 - 1]]}
          stroke="#5DD55D"
          strokeWidth={3}
        />
      );
    }
    // return the lines, but Each child in a list should have a unique "key" prop.
    return lines.map((line, index) => {
      return <React.Fragment key={index}>{line}</React.Fragment>;
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      p={1}
      textAlign={"-webkit-center"}
    >
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item lg={12} xl={12} >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width={650}
              height={650}
            >
              <Box
                width={601}
                height={601}
              >
                <Stage width={601} height={601}>
                  <Layer>
                    <Line
                      points={[100, 0, 100, 500]}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Line
                      points={[100, 500, 500, 500]}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Line
                      points={[500, 500, 500, 0]}
                      stroke="black"
                      strokeWidth={1}
                    />

                    <Text
                      x={10}
                      y={450}
                      width={400}
                      align="center"
                      rotation={270}
                      text={t('contrastSensitivityTestLocale.contrasChart')}
                      fontSize={25}
                      fontFamily="system-ui"
                    />
                    {setYAxisLines(
                      [490.0, 416.7948717948718, 360.51282051282055, 288.33333333333337, 199.35897435897442, 123.0769230769231, 40.0],
                      ["2", "4", "10", "20", "50", "100", "200"],
                      true
                    )}

                    <Text
                      x={560}
                      y={450}
                      width={400}
                      align="center"
                      rotation={270}
                      text={t('contrastSensitivityTestLocale.percentContrast')}
                      fontSize={25}
                      fontFamily="system-ui"
                    />
                    {setYAxisLines(
                      [490.0, 416.7948717948718, 360.51282051282055, 288.33333333333337, 199.35897435897442, 123.0769230769231, 40.0],
                      ["50", "25", "10", "5", "2", "1", "0.5"],
                      false
                    )}

                    <Text
                      x={100}
                      y={550}
                      width={400}
                      align="center"
                      text={t('contrastSensitivityTestLocale.spatialFrequency')}
                      fontSize={25}
                      fontFamily="system-ui"
                    />
                    {setXAxisLines(
                      [150, 250, 350, 450],
                      ["1.5", "3", "6", "12"]
                    )}

                    <Line
                      x={0}
                      y={0}
                      points={[110, 180, 230, 120, 350, 70, 490, 145,
                        490, 240, 350, 220, 230, 220, 110, 270]}
                      closed
                      stroke="grey"
                      fill="#f0f0f0"
                    />
                    {renderContrastSensitivityLines()}

                    {setDots(0)}

                    {setDots(1)}

                    {setDots(2)}

                    {setDots(3)}
                  </Layer>
                </Stage>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};
export default ContrastSensitivity12;