import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Slider,
  Button,
  Divider,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CampimetryPDF from "../../components/PDFdocuments/CampimetryPDF";
import CampimetryStateCardsComponent from "../../components/CampimetryStateCardsComponent";

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';

const CampimetryForm = () => {
  const [campimetrySection, setCampimetrySection] = React.useState("");
  const [eye, setEye] = React.useState("");
  const [stimulusSize, setStimulusSize] = React.useState(0);
  const [centerSize, setCenterSize] = React.useState(0);
  const [calibrationRequired, setCalibrationRequired] = React.useState(true);
  const [eyeFixationSensitivity, setEyeFixationSensitivity] = React.useState(4);
  const [contrastPattern, setContrastPattern] = React.useState("");
  const [campimetryPosition, setCampimetryPosition] = React.useState("");
  const [escotoma, setescotoma] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const { uid } = useParams();
  const { currentUser, updateLastTestDate } = useContext(AuthContext);

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const { t } = useTranslation();

  // region Test, Option and Instructions Dialogs
  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region Snackbar logic
  const handleSnackbar = () => {
    setSnackbarMessage(t('generalTestLocale.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  // region Form submission
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSliderChange = (event, newValue) => {
    setEyeFixationSensitivity(newValue);
  };

  const handleInputChange = (event) => {
    setEyeFixationSensitivity(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleBlur = () => {
    if (eyeFixationSensitivity < 1) {
      setEyeFixationSensitivity(1);
    } else if (eyeFixationSensitivity > 15) {
      setEyeFixationSensitivity(15);
    }
  };

  const handleCheckboxChange = (event) => {
    setescotoma(event.target.checked);
  };

  const handleCalibrationRequieredCheckbox = (event) => {
    setCalibrationRequired(event.target.checked);
  };

  const allFieldsValid = () => {
    if (campimetrySection === "pheriferical 120") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "peripherical_complete_120", "up", "down", "right", "left"
      if (!["peripherical_complete_120", "up", "down", "right", "left"].includes(campimetryPosition)) {
        return false;
      }
    } else if (campimetrySection === "pheriferical 110") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "peripherical_complete_110", "up_25_degrees", "down_25_degrees", "right_25_degrees", "left_25_degrees"
      if (!["peripherical_complete_110", "up_25_degrees", "down_25_degrees", "right_25_degrees", "left_25_degrees"].includes(campimetryPosition)) {
        return false;
      }
    } else if (campimetrySection === "central") {
      // check if campimetryPosition is not empty and is a valid value for this section
      if (campimetryPosition === "") {
        return false;
      }
      // campimetryPosition possible values are "center_30_degrees", "center_20_degrees", "center_10_degrees"
      if (!["center_30_degrees", "center_20_degrees", "center_10_degrees"].includes(campimetryPosition)) {
        return false;
      }
    }

    return campimetrySection && eye && contrastPattern && campimetryPosition;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "campimetry",
      config_eye: eye,
      config_calibration_required: calibrationRequired,
      config_eye_fixation_sensitivity: eyeFixationSensitivity,
      config_campimetry_contrast_pattern: contrastPattern,
      config_campimetry_position: campimetryPosition,
      config_campimetry_stimulus_size: stimulusSize,
      config_campimetry_center_size: centerSize,
      config_scotoma_help: escotoma,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const renderSubCampimetrySection = () => {
    if (campimetrySection === "pheriferical 120") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel
            htmlFor="glasses-select"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {t('campimetryTestLocale.campimetryPosition')}
          </InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_120">
              {t('generalTestLocale.complete')}
            </MenuItem>
            <MenuItem value="up">{t('generalTestLocale.down')}</MenuItem>
            <MenuItem value="down">{t('generalTestLocale.up')}</MenuItem>
            <MenuItem value="right">{t('generalTestLocale.left')}</MenuItem>
            <MenuItem value="left">{t('generalTestLocale.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (campimetrySection === "pheriferical 110") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetryTestLocale.campimetryPosition')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry Section"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_110">
              {t('generalTestLocale.complete')}
            </MenuItem>
            <MenuItem value="up_25_degrees">{t('generalTestLocale.down')}</MenuItem>
            <MenuItem value="down_25_degrees">{t('generalTestLocale.up')}</MenuItem>
            <MenuItem value="right_25_degrees">{t('generalTestLocale.left')}</MenuItem>
            <MenuItem value="left_25_degrees">{t('generalTestLocale.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (campimetrySection === "central") {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetryTestLocale.campimetryPosition')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="center_30_degrees">
              {t('campimetryTestLocale.thirtyDegrees')}
            </MenuItem>
            <MenuItem value="center_20_degrees">
              {t('campimetryTestLocale.twentyDegrees')}
            </MenuItem>
            <MenuItem value="center_10_degrees">
              {t('campimetryTestLocale.tenDegrees')}
            </MenuItem>
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>{t('campimetryTestLocale.campimetryPosition')}</InputLabel>
          <Select
            value={campimetryPosition}
            label="Campimetry position"
            onChange={(e) => setCampimetryPosition(e.target.value)}
          >
            <MenuItem value="peripherical_complete_120">
              {t('generalTestLocale.complete')}
            </MenuItem>
            <MenuItem value="up">{t('generalTestLocale.up')}</MenuItem>
            <MenuItem value="down">{t('generalTestLocale.down')}</MenuItem>
            <MenuItem value="left">{t('generalTestLocale.left')}</MenuItem>
            <MenuItem value="right">{t('generalTestLocale.right')}</MenuItem>
          </Select>
        </FormControl>
      );
    }
  };
  // endregion

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('campimetryTestLocale.campimetry')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('generalTestLocale.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('generalTestLocale.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('generalTestLocale.right')}</MenuItem>
                <MenuItem value="left">{t('generalTestLocale.left')}</MenuItem>
                <MenuItem value="none">{t('campimetryTestLocale.both')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('campimetryTestLocale.campimetrySection')}
              </InputLabel>
              <Select
                value={campimetrySection}
                label="Campimetry section"
                onChange={(e) => setCampimetrySection(e.target.value)}
              >
                <MenuItem value="central">{t('campimetryTestLocale.center')}</MenuItem>
                <MenuItem value="pheriferical 110">
                  {t('campimetryTestLocale.pherifericalten')}
                </MenuItem>
                <MenuItem value="pheriferical 120">
                  {t('campimetryTestLocale.pherifericaltwenty')}
                </MenuItem>
              </Select>
            </FormControl>
            {renderSubCampimetrySection()}
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "normal", // Change to 'normal' to allow the text to wrap
                  lineHeight: "1.4375em", // Maintain readability with standard line height
                  paddingTop: "6px", // Add padding to ensure text does not touch the border
                }}
              >
                {t('campimetryTestLocale.campimetryContrastPattern')}
              </InputLabel>
              <Select
                value={contrastPattern}
                label={t('campimetryTestLocale.campimetryContrastPattern')} // Ensure this matches the InputLabel for proper sizing
                onChange={(e) => setContrastPattern(e.target.value)}
                inputProps={{
                  name: "campimetry-contrast-pattern",
                  id: "glasses-select",
                }}
              >
                <MenuItem value="0-1">{t('campimetryTestLocale.ceroStrategy')}</MenuItem>
                <MenuItem value="high-medium-low">
                  {t('campimetryTestLocale.highMediumLow')}
                </MenuItem>
                <MenuItem value="4-2-1">4-2-1</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('campimetryTestLocale.stimulusSize')}
              </InputLabel>
              <Select
                label={t('campimetryTestLocale.stimulusSize')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusSize}
                onChange={(e) => setStimulusSize(e.target.value)}
              >
                <MenuItem value={0}>{t('campimetryTestLocale.normal')}</MenuItem>
                <MenuItem value={1}>{t('campimetryTestLocale.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('campimetryTestLocale.grandeII')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="center-size"
                style={{
                  whiteSpace: "normal", // Allow the label to wrap
                  lineHeight: "1.4375em", // Default line height from MUI theme
                  paddingTop: "6px", // Provide some padding to the top when label wraps
                }}
              >
                {t('campimetryTestLocale.centerSize')}
              </InputLabel>
              <Select
                // Ensuring the `label` prop is set to match the `InputLabel` text
                label={t('campimetryTestLocale.centerSize')}
                inputProps={{
                  name: "centersize",
                  id: "center-size",
                }}
                value={centerSize}
                onChange={(e) => setCenterSize(e.target.value)}
              >
                <MenuItem value={0}>{t('campimetryTestLocale.normal')}</MenuItem>
                <MenuItem value={1}>{t('campimetryTestLocale.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('campimetryTestLocale.grandeII')}</MenuItem>
              </Select>
            </FormControl>
            <Typography id="input-slider" gutterBottom>
              {t('campimetryTestLocale.eyeFixationSensitivity')}
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  aria-labelledby="input-slider"
                  onChange={handleSliderChange}
                  value={eyeFixationSensitivity}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={15}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={eyeFixationSensitivity}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 15,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
            >
              <FormControlLabel
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={escotoma}
                    onChange={handleCheckboxChange}
                    name="Ayuda escotoma central"
                  />
                }
                label={t('campimetryTestLocale.scotomaHelp')}
              />
              <FormControlLabel
                variant="outlined"
                margin="dense"
                control={
                  <Checkbox
                    checked={calibrationRequired}
                    onChange={handleCalibrationRequieredCheckbox}
                    name="calibration"
                  />
                }
                label={t('campimetryTestLocale.calibration')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('formsLocale.submit')}
          </Button>
        </Box>
        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>
        <CampimetryStateCardsComponent />

        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={startGeneratingPDF}
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('pdfLocale.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>Ojo:</strong> realizar la prueba en monocular o en binocular.
            <ul>
              <li><em>Derecho:</em> monocular ojo derecho</li>
              <li><em>Izquierdo:</em> monocular ojo izquierdo</li>
              <li><em>Ambos:</em> se realizará la prueba en ambos ojos (binocular)</li>
            </ul>
            <strong>Campo visual:</strong> seleccionamos el campo a evaluar:
            <ul>
              <li><em>Central:</em> evalúa de fóvea a 10, 20 ó 30 grados perifoveales</li>
              <li><em>Periférico 110º:</em> evalúa de 30 grados perifoveales a 55 grados</li>
              <li><em>Periférico 120º:</em> evalúa de 30 grados perifoveales a 60 grados</li>
            </ul>
            <strong>Campo a evaluar:</strong> seleccionamos el tamaño del área a evaluar:
            <ul>
              <li>
                <em>Si Campo visual es central:</em>
                <ul>
                  <li>10 grados: se evalúan 76 puntos de la retina, separados 2º entre sí</li>
                  <li>20 grados: se evalúan 52 puntos de la retina, separados 6º entre sí</li>
                  <li>30 grados: se evalúan 76 puntos de la retina, separados 6º entre sí</li>
                </ul>
              </li>
              <li>
                <em>Si Campo visual es periférico 110º:</em>
                <ul>
                  <li>Superior 110º: se evalúan 24 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 25º hacia abajo</li>
                  <li>Inferior 110º: se evalúan 18 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 25º hacia arriba</li>
                  <li>Izquierdo 110º: se evalúan 21 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 25º hacia la derecha</li>
                  <li>Derecho 110º: se evalúan 21 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 25º hacia la izquierda.</li>
                  <li>Completo 110º: se evalúan las 4 áreas anteriormente explicadas en el siguinte orden: campo derecho, campo izquierdo, campo inferior y campo superior.</li>
                </ul>
              </li>
              <li>
                <em>Si Campo visual es periférico 120º:</em>
                <ul>
                  <li>Superior 120º: se evalúan 32 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 30º hacia abajo</li>
                  <li>Inferior 120º: se evalúan 26 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 30º hacia arriba</li>
                  <li>Izquierdo 120º: se evalúan 29 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 30º hacia la derecha</li>
                  <li>Derecho 120º: se evalúan 29 puntos de la retina, separados 6º entre sí. El punto de fijación aparecerá desplazado 30º hacia la izquierda</li>
                  <li>Completo 120º: se evalúan las 4 áreas anteriormente explicadas en el siguinte orden: campo derecho, campo izquierdo, campo inferior y campo superior</li>
                </ul>
              </li>
            </ul>
            <strong>Estrategia de la campimetría:</strong> según lo que quremos evaluar y el tiempo disponible tenemos las siguientes estrategias para realizar la medida del campo visual:
            <ul>
              <li><em>0 - 1 visto o no visto:</em> en este tipo de campimetría se determina la totalidad del campo visual sin tener en cuenta la sensibilidad de cada área. Los estímulos presentan un 100% de contraste respecto al fondo. Con esta prueba obtendremos: el área total del campo visual con los puntos vistos y los no vistos, los resultados divididos en cuadrantes y en zonas de mirada y los tiempos de reacción. Esta prueba contiene falsos positivos</li>
              <li><em>4 intervalos:</em> en este tipo de campimetría se determina el campo visual, indicando en cada zona evaluada de la retina su sensibilidad medida en: 0dB, 5dB, 15dB y 20dB. Con esta prueba obtendremos: el área total del campo visual con los puntos vistos y los no vistos, los resultados divididos en cuadrantes y en zonas de mirada y el mapa de grises característico. Los puntos morados que pueden aparecer en el mapa de grises son los puntos "no vistos". Esta prueba contienn falsos positivos y falsos negativos</li>
              <li><em>4 - 2 - 1:</em> en este tipo de campimetría se determina el campo visual, indicando en cada zona evaluada de la retina su sensibilidad con un error de +/-1dB. Con esta prueba obtendremos de la manera más exacta posible: el área total del campo visual con los puntos vistos y los no vistos, los resultados divididos en cuadrantes y en zonas de mirada y el mapa de grises característico. Los puntos morados que pueden aparecer en el mapa de grises son los puntos "no vistos". Esta prueba contienn falsos positivos y falsos negativos</li>
            </ul>
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>Tamaño del estímulo:</strong> podemos variar el tamaño del estímulo:
            <ul>
              <li><em>Normal:</em> cada estímulo tiene un tamaño de 2.5cm de Ø, en un test presentado a 2.5m</li>
              <li><em>Grande I:</em> cada estímulo tiene un tamaño de 5cm de Ø, en un test presentado a 2.5m</li>
              <li><em>Grande II:</em> cada estímulo tiene un tamaño de 10cm de Ø, en un test presentado a 2.5m</li>
            </ul>
            <strong>Tamaño del centro de fijación:</strong> podemos variar la cruz central del centro de fijación:
            <ul>
              <li><em>Normal:</em> la cruz de fijación tiene un tamaño de 9cm, en un test presentado a 2.5m</li>
              <li><em>Grande I:</em> la cruz de fijación tiene un tamaño de 18cm, en un test presentado a 2.5m</li>
              <li><em>Grande II:</em> la cruz de fijación tiene un tamaño de 36cm, en un test presentado a 2.5m</li>
            </ul>
            <strong>Sensibilidad del Eye Tracker:</strong> podemos variar el área permitida por el Eye Tracker donde los ojos se pueden mover sin que la prueba se pare. Esto es útil en problemas de fijación, como nistagmo o en evaluaciones de "Campo periférico 120º" donde el usuario debe mirar en una posición de mirada al límite del campo del visor. Viene delimitado en grados y recomendamos realizar la prueba en 4º.
            <br /><br />
            <strong>Ayuda escotoma central:</strong> aparecerá una X y, en su centro, estará el "Centro de fijación". Cuando el paciente no vea el centro por un escotoma podemos pedirle que mire donde intuya que las 4 líneas que vea corten.
            <br /><br />
            <strong>Calibrado:</strong> el calibrado del Eye Tracker debe realizarse cada vez que un paciente se coloque el visor de realidad virtual. Una vez realizado el calibrado el visor no podrá moverse ni quitarse. Si se mueve o se quita el visor, debemos volver a seleccionar el calibrado.
            <br /><br />
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            Por favor, mire al centro de la cruz que aparecerá a continuación sin apartar la mirada. Cada vez que aparezca un estímulo visual pulse el botón <strong>trigger/gatillo</strong>.
            <br /><br />
            A veces oirá un estímulo auditivo que no va acompañado de uno visual, por favor, pulse solo cuando haya estímulo visual
            <br /><br />
            Si en algún momento quiere parar la prueba, puede cerrar los ojos. Se reanudará al abrirlos
            <br /><br />
            <strong>Controles:</strong> Los botones, así como la imagen, hacen referencia al controlador derecho.
            <ul>
              <li><em>Botón B:</em> Volver al menú principal</li>
              <li><em>Botón trigger/gatillo:</em> Marcar estímulos vistos</li>
            </ul>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>Controlador derecho</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <CampimetryPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
      >
      </CampimetryPDF>

    </React.Fragment>
  );
};

export default CampimetryForm;
