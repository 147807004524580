import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from '@mui/icons-material/History';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoImage from "../assets/logo_dicopt.png";

const PatientDrawerComponent = ({
  anchor
}) => {

  const { t } = useTranslation();

  const { uid } = useParams();

  return (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <Link to="/dashboard">
        <img
          src={LogoImage}
          alt="Logo"
          style={{ height: "40px", padding: 20, cursor: "pointer" }} // Add cursor style for better UX
        />
      </Link>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Button
          startIcon={<HomeIcon />}
          component={Link}
          to={`/patient/${uid}`}

          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('patientOverview')}
        </Button>
        <Button
          startIcon={<HistoryIcon />}
          component={Link}
          to={`/patient/${uid}/historial`}

          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('history')}
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        {/*
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/visualacuity`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('eSnellenTestLocale.eSnellen')}
        </Button>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/dynamicvisualacuity`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('dynamicVATestLocale.dynamicVA')}
        </Button>
        */}
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/campimetria`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('campimetryTestLocale.campimetry')}
        </Button>
        {/*
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/foria`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('foriaTestLocale.foria')}
        </Button>
        */}
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/hesslancaster`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('hessLancasterTestLocale.hessLancaster')}
        </Button>        
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/amslergrid`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('amslerGridTestLocale.amslerGrid')}
        </Button>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/contrastsensitivity`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('contrastSensitivityTestLocale.contrastSensitivity')}
        </Button>
        <Button
          startIcon={<PlayArrowIcon />}
          component={Link}
          to={`/patient/${uid}/worthtest`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('worthTestLocale.worth')}
        </Button>
      </Box>
      <Divider />

      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: 2,
          marginLeft: 2,
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          component={Link}
          to={"/dashboard"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            color: "#041e49",
            width: "100%",
          }}
        >
          {t('exit')}
        </Button>
      </Box>
    </Box>
  );
};

export default PatientDrawerComponent;
