import {
  createTheme, ThemeProvider,
  Box,
  Typography,
  Divider,
  Grid
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext";
import { ref, get } from "firebase/database";
import { database } from "../../firebaseConfig";

const theme = createTheme({
  typography: {
    body2: {
      fontSize: '0.7rem', // Ajusta el tamaño de la letra aquí
    },
  },
});

const PDFHeader = ({ testDate = "" }) => {
  const { currentUser } = useContext(AuthContext);
  const { uid } = useParams();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const originalUserRef = ref(database, `patients/${currentUser.uid}/${uid}`);
    // Obtener los datos del usuario original
    get(originalUserRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val());
        }
      })
      .catch((error) => {
        console.error("Error al copiar y eliminar el usuario:", error);
      });
  }, [currentUser]);

  const formatDate = (date) => {
    if (date === "") {
      let fechaActual = new Date();

      const pad = (num) => (num < 10 ? '0' : '') + num;

      const hours = pad(fechaActual.getHours());
      const minutes = pad(fechaActual.getMinutes());
      const seconds = pad(fechaActual.getSeconds());

      const day = pad(fechaActual.getDate());
      const month = pad(fechaActual.getMonth() + 1); // Los meses empiezan desde 0
      const year = fechaActual.getFullYear();

      return `${hours}:${minutes}:${seconds} - ${day}/${month}/${year}`;
    }
    return date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4) + " - " + date.slice(9, 11) + ":" + date.slice(11, 13) + ":" + date.slice(13, 15);
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Nombre:</strong> {userData.name + " " + userData.surname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Fecha de nacimiento:</strong> {userData.birthDate}
              </Typography>
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pl: 2 }}>
                <strong>Fecha de prueba:</strong> {formatDate(testDate)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      <Divider variant="middle" sx={{ mb: 2 }}></Divider>
    </React.Fragment>
  );
}

export default PDFHeader;