// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";

import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import PermissionsRoute from './routes/PermissionsRoute';

import { AuthProvider } from "./providers/AuthContext";
import { HeartbeatProvider } from "./providers/HeartbeatContext";

import Dashboard from "./pages/Dashboard/Dashboard";
import Patient from "./pages/Patient/Patient";
import Login from "./pages/Login/Login";
import MainContent from "./pages/Patient/MainContent";
import UpdateInfo from "./pages/Patient/UpdateInfo";
import CampimetryForm from "./pages/Test/CampimetryForm";
import HessLancasterForm from "./pages/Test/HessLancasterForm";
import ESnellenForm from "./pages/Test/ESnellenForm";
import AmslerGridForm from "./pages/Test/AmslerGridForm";
import ContrastSensitivityForm from "./pages/Test/ContrastSensitivityForm";
import DynamicVAForm from "./pages/Test/DynamicVAForm";
import WorthTestForm from "./pages/Test/WorthTestForm";
import ForiaForm from "./pages/Test/ForiaForm";

import PreviewPDF from "./components/PDFdocuments/PreviewPDF";
import History from "./pages/Patient/History";

function App() {
  return (
    <AuthProvider>
      <HeartbeatProvider>
        <Routes>
          <Route element={<AuthenticatedRoute />} >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/patient/:uid" element={<Patient />} >
              <Route index element={<MainContent />} />
              <Route path="update" element={
                <UpdateInfo />
              } />
              <Route path="historial" element={
                <History />
              } />
              <Route path="campimetria" element={
                <PermissionsRoute requiredPermission="campimetry">
                  <CampimetryForm />
                </PermissionsRoute>
              } />
              <Route path="hesslancaster" element={
                <PermissionsRoute requiredPermission="hess_lancaster">
                  <HessLancasterForm />
                </PermissionsRoute>
              }
              />
              <Route path="amslergrid" element={
                <PermissionsRoute requiredPermission="amsler_grid">
                  <AmslerGridForm />
                </PermissionsRoute>
              }
              />
              <Route path="worthtest" element={
                <PermissionsRoute requiredPermission="worth_test">
                  <WorthTestForm />
                </PermissionsRoute>
              }
              />
              <Route path="contrastsensitivity" element={
                <PermissionsRoute requiredPermission="contrast_sensitivity">
                  <ContrastSensitivityForm />
                </PermissionsRoute>
              }
              />
              {/*
              <Route
                path="foria"
                element={
                  <ForiaForm />
                }
              />
              <Route
                path="visualacuity"
                element={
                  <ESnellenForm />
                }
              />
              <Route
                path="dynamicvisualacuity"
                element={
                  <DynamicVAForm />
                }
              />
              */}
            </Route>
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="previwePDF" element={<PreviewPDF />} />
        </Routes>
      </HeartbeatProvider>
    </AuthProvider>
  );
}

export default App;
