import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LanguageIcon from "@mui/icons-material/Language";

import { AuthContext } from "../providers/AuthContext";

const DashboardAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLang, setAnchorLagn] = useState(null);
  const { logout } = useContext(AuthContext);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleLangMenuOpen = (event) => {
    setAnchorLagn(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setAnchorLagn(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose(); // Close the menu after logout
    navigate("/"); // Redirect to login page after logout using navigate
  };

  const handleLocale = (selectedLocale) => {
    setAnchorLagn(null); // Close language menu
    i18n.changeLanguage(selectedLocale);
  }

  return (
    <AppBar
      position="static"
      sx={{
        paddingRight: "40px",
        height: "200px",
        alignItems: "flex-start",
        backgroundColor: "#a4c5fb",
        color: "#041e49",
      }}
    >
      <Toolbar
        sx={{
          height: "64px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          pt: 1,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />

        <IconButton color="inherit" onClick={handleLangMenuOpen}>
          <LanguageIcon />
        </IconButton>
        <Menu
          anchorEl={anchorLang}
          open={Boolean(anchorLang)}
          onClose={handleLangMenuClose}
        >
          <MenuItem onClick={() => handleLocale("en")}>
            {t('english')}
          </MenuItem>
          <MenuItem onClick={() => handleLocale("es")}>
            {t('spanish')}
          </MenuItem>
        </Menu>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>{t('formsLocale.logOut')}</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardAppBar;
