import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Modal, Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { AuthContext } from "../../providers/AuthContext";
import LogoImage from "../../assets/logo_dicopt.png";
import BackgroundImage from "../../assets/background.jpeg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // can be 'error', 'warning', 'info', 'success'
  const navigate = useNavigate();
  const { login, userLoggedIn } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (userLoggedIn) {
      navigate("/dashboard");
    }
    setIsLoading(false);
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const idToken = await userCredential.user.getIdToken();

      // Send this ID token to your server
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/server/login`,
        {
          idToken,
        }
      );
      login(userCredential.user);
      navigate("/dashboard");
    } catch (error) {
      const errorMessage = error.message;
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const backgroundStyle = {
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    filter: "blur(8px)",
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={backgroundStyle}>
        <Modal
          open={true}
          onClose={() => {}} // Prevent modal from being closed
          aria-labelledby="login-modal-title"
          aria-describedby="login-modal-description"
        >
          <Box sx={modalStyle} component="form" onSubmit={handleLogin}>
            <Box sx={{ width: { xs: "100%", md: "50%" }, mr: { md: 2 } }}>
              <img src={LogoImage} alt="Logo" style={{ maxWidth: "100%" }} />
            </Box>
            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
              <TextField
                label={t('formsLocale.emailAddress')}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label={t('formsLocale.password')}
                type="password"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type="submit" variant="contained" fullWidth>
                {t('formsLocale.logIn')}
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
      <Snackbar
        sx={{ zIndex: 1500 }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
