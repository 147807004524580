import React, { useState, useContext } from "react";
import { AuthContext } from "../../providers/AuthContext";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

import { ref, get, set, remove } from 'firebase/database';
import { database } from '../../firebaseConfig';

export default function DeletePatientDialog({ isOpen, onClose, patientID, patientString }) {
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const deletePatient = () => {
    if(currentUser.uid == null || currentUser.uid === "" || patientID == null || patientID === ""){
      return;
    }

    setIsLoading(true);
    // Referencia al usuario original
    const originalUserRef = ref(database, `patients/${currentUser.uid}/${patientID}`);

    // Referencia donde se copiará el nuevo usuario
    const newUserRef = ref(database, `patientsDeleted/${currentUser.uid}/${patientID}`);

    // Obtener los datos del usuario original
    get(originalUserRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();

          // Copiar los datos a la nueva ubicación
          return set(newUserRef, userData)
            .then(() => {
              // Eliminar el usuario original
              return remove(originalUserRef);
            })
            .then(() => {
              console.log(`Usuario copiado y eliminado con éxito.`);
              setIsLoading(false);
              onClose();
            });
        } else {
          console.log(`Usuario no existe.`);
        }
      })
      .catch((error) => {
        console.error("Error al copiar y eliminar el usuario:", error);
      });
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>
        Eliminar paciente
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Estás seguro de que quieres eliminar la información de {patientString}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          isLoading ?
            <CircularProgress />
            :
            <React.Fragment>
              <Button onClick={onClose} sx={{ "m": 1 }}>Cancelar</Button>
              <Button variant="contained" color="error" sx={{ "m": 1 }} onClick={deletePatient}>
                Borrar
              </Button>
            </React.Fragment>
        }
      </DialogActions>
    </Dialog>
  );
}
