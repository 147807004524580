import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../providers/AuthContext";
import { HeartbeatContext } from "../../providers/HeartbeatContext";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Button,
  Divider,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { ref, update, onValue, off, get } from "firebase/database";
import { database } from "../../firebaseConfig";

// ICONS
import InfoIcon from '@mui/icons-material/Info';

import FrontJoystick from '../../assets/frontJoystick.png';
import SideJoystick from '../../assets/sideJoystick.png';
import StartTestButton from "../../components/buttons/StartTestButton";
import SkipPointButton from "../../components/buttons/SkipPointButton";
import HessLancasterGrid from "../../components/Charts/HessLancasterGrid";
import HessLancasterPDF from "../../components/PDFdocuments/HessLancasterPDF";

const HessLancasterForm = () => {
  const { currentUser, updateLastTestDate } = useContext(AuthContext);
  const { uid } = useParams();

  const [eye, setEye] = useState("");
  const [stimulusType, setStimulusType] = useState("punctual");
  const [stimulusSize, setStimulusSize] = React.useState(0);
  const [hessLancasterStatus, setHessLancasterStatus] = React.useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isShowingValues, setIsShowingValues] = useState(false);

  const [openTestParam, setOpenTestParam] = React.useState(false);
  const [openOptionParam, setOpenOptionParam] = React.useState(false);
  const [openInstructions, setOpenInstructions] = React.useState(false);

  const { t } = useTranslation();
  
  // region Check Status
  const { isAppAlive } = useContext(HeartbeatContext);
  const [hessLancasterData, setHessLancasterData] = React.useState({
    stimulusType: "punctual",
    maxLeftPoint: 0,
    maxRightPoint: 0,
    leftPos: [],
    rightPos: [],
    leftRot: [],
    rightRot: []
  });
  // status is readed at the beginning, even if the app is not alive
  useEffect(() => {
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;
    const hessLancasterStatusRef = ref(database, `hess_lancaster_status/${currentUser.uid}`);
    get(hessLancasterStatusRef).then((snapshot) => {
      const hessLancasterVal = snapshot.val();
      if (hessLancasterVal && hessLancasterVal?.actual_user === uid) {
        setHessLancasterStatus(hessLancasterVal);
        formatHessLancasterData(hessLancasterVal);
      }
      else {
        setHessLancasterStatus({
          in_test: false
        });
      }
    });
  }, [currentUser]);

  // status is readed when the app is alive, besides, the status is updated when it changes
  useEffect(() => {
    if (!isAppAlive) return;
    if (!currentUser || currentUser.uid === null || currentUser.uid === "") return;

    const hessLancasterStatusRef = ref(database, `hess_lancaster_status/${currentUser.uid}`);
    const fetchHessLancasterStatus = (snapshot) => {
      const hessLancasterVal = snapshot.val();
      if (hessLancasterVal && hessLancasterVal?.actual_user === uid) {
        setHessLancasterStatus(hessLancasterVal);
        formatHessLancasterData(hessLancasterVal);
      }
      else {
        setHessLancasterStatus({
          in_test: false
        });
      }
    };
    onValue(hessLancasterStatusRef, fetchHessLancasterStatus);

    return () => {
      off(hessLancasterStatusRef, 'value', fetchHessLancasterStatus);
    };
  }, [isAppAlive]);

  // this function receives a status and set the hessLancasterData
  const formatHessLancasterData = (statusValues) => {
    // check if hessLancasterStatus is null, if so, return null
    if (!statusValues) {
      setHessLancasterData({
        stimulusType: "punctual",
        maxLeftPoint: 0,
        maxRightPoint: 0,
        leftPos: [],
        rightPos: [],
        leftRot: [],
        rightRot: []
      });
      return null;
    }

    const auxStimulusType = statusValues?.status_hess_lancaster_stimulus_type || "punctual";
    const boolIsPunctual = auxStimulusType === "punctual";

    setHessLancasterData({
      stimulusType: auxStimulusType,
      maxLeftPoint: boolIsPunctual ? statusValues?.actual_point_left_punctual : statusValues?.actual_point_left_vertical,
      maxRightPoint: boolIsPunctual ? statusValues?.actual_point_right_punctual : statusValues?.actual_point_right_vertical,
      leftPos: boolIsPunctual ? statusValues?.left_eye_pos_punctual : statusValues?.left_eye_pos_vertical,
      rightPos: boolIsPunctual ? statusValues?.right_eye_pos_punctual : statusValues?.right_eye_pos_vertical,
      leftRot: boolIsPunctual ? statusValues?.left_eye_rot_punctual : statusValues?.left_eye_rot_vertical,
      rightRot: boolIsPunctual ? statusValues?.right_eye_rot_punctual : statusValues?.right_eye_rot_vertical
    });
  };
  // endregion

  // region Info Dialogs
  const handleClickOpenTestParam = () => () => {
    setOpenTestParam(true);
  };

  const handleCloseTestParam = () => {
    setOpenTestParam(false);
  };

  const descriptionTestParamRef = useRef(null);
  React.useEffect(() => {
    if (openTestParam) {
      const { current: descriptionTestParam } = descriptionTestParamRef;
      if (descriptionTestParam !== null) {
        descriptionTestParam.focus();
      }
    }
  }, [openTestParam]);

  const handleClickOpenOptionParam = () => () => {
    setOpenOptionParam(true);
  };

  const handleCloseOptionParam = () => {
    setOpenOptionParam(false);
  };

  const descriptionOptionParamRef = useRef(null);
  React.useEffect(() => {
    if (openOptionParam) {
      const { current: descriptionOptionParam } = descriptionOptionParamRef;
      if (descriptionOptionParam !== null) {
        descriptionOptionParam.focus();
      }
    }
  }, [openOptionParam]);

  const handleClickOpenInstructions = () => () => {
    setOpenInstructions(true);
  };

  const handleCloseInstructions = () => {
    setOpenInstructions(false);
  };

  const descriptionInstructionsRef = useRef(null);
  React.useEffect(() => {
    if (openInstructions) {
      const { current: descriptionInstructions } = descriptionInstructionsRef;
      if (descriptionInstructions !== null) {
        descriptionInstructions.focus();
      }
    }
  }, [openInstructions]);
  // endregion

  // region PDF generation
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const startGeneratingPDF = () => setGeneratingPDF(true);
  const stopGeneratingPDF = () => setGeneratingPDF(false);
  // endregion

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSnackbar = () => {
    setSnackbarMessage(t('generalTestLocale.success'));
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const allFieldsValid = () => {
    return eye !== "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      config_test_type: "hess_lancaster",
      config_eye: eye,
      config_hess_lancaster_stimulus_size: stimulusSize,
      config_hess_lancaster_stimulus_type: stimulusType,
      config_user_id: uid,
    };
    const userId = currentUser.uid;

    // esto es para resetear el status si el usuario cambia
    if (formData.config_user_id !== hessLancasterStatus?.actual_user) {
      // escribimos, esta vez, directamente en realtime actualizando el status
      const db = database;
      const dbRef = ref(db, `hess_lancaster_status/${userId}`);
      update(dbRef, {
        actual_user: formData.config_user_id,
        actual_point_left_punctual: 0,
        actual_point_right_punctual: 0,
        actual_point_left_vertical: 0,
        actual_point_right_vertical: 0
      });
    }

    axios
      .post(
        `${SERVER_URL}/server/dicoptpro-config/${userId}`,
        formData
      )
      .then((response) => {
        console.log("Form submitted successfully", response.data);

        handleSnackbar();
        updateLastTestDate(uid);
      })
      .catch((error) => {
        console.error("Error submitting form", error);
      });

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  // Inside your component's return statement
  return (
    <React.Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          {t('hessLancasterTestLocale.hessLancaster')}
          <Button color="primary" variant="contained" onClick={handleClickOpenInstructions()} sx={{ ml: 3, borderRadius: 28 }}>
            {t('generalTestLocale.instructions')}
          </Button>
        </Typography>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.parametersTitle')}
              <IconButton onClick={handleClickOpenTestParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="glasses-select"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t('generalTestLocale.eye')}
              </InputLabel>
              <Select
                value={eye}
                label="Eye"
                onChange={(e) => setEye(e.target.value)}
              >
                <MenuItem value="right">{t('generalTestLocale.right')}</MenuItem>
                <MenuItem value="left">{t('generalTestLocale.left')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              {t('generalTestLocale.optionsTitle')}
              <IconButton onClick={handleClickOpenOptionParam()} aria-label="delete" sx={{ ml: 3 }}>
                <InfoIcon color="action" />
              </IconButton>
            </Typography>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('mainGridScreenLocale.stimulusType')}
              </InputLabel>
              <Select
                label={t('mainGridScreenLocale.stimulusType')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusType}
                onChange={(e) => setStimulusType(e.target.value)}
              >
                <MenuItem value="punctual">{t('mainGridScreenLocale.point')}</MenuItem>
                <MenuItem value="vertical">{t('mainGridScreenLocale.vertical')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel
                htmlFor="stimulus-size"
                style={{
                  // Adjust the style to allow for wrapping and ensure adequate spacing
                  whiteSpace: "normal", // Allow the label to wrap onto multiple lines as needed
                  lineHeight: "1.4375em", // Standard line height to maintain vertical rhythm
                  paddingTop: "6px", // Add a bit of padding to the top to ensure wrapped text doesn't touch the input border
                }}
              >
                {t('campimetryTestLocale.stimulusSize')}
              </InputLabel>
              <Select
                label={t('campimetryTestLocale.stimulusSize')} // Ensure this matches the text of the InputLabel for proper alignment and sizing
                inputProps={{
                  name: "stimulus-size",
                  id: "stimulus-size",
                }}
                value={stimulusSize}
                onChange={(e) => setStimulusSize(e.target.value)}
              >
                <MenuItem value={0}>{t('campimetryTestLocale.normal')}</MenuItem>
                <MenuItem value={1}>{t('campimetryTestLocale.grandeI')}</MenuItem>
                <MenuItem value={2}>{t('campimetryTestLocale.grandeII')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>
        <Box sx={{ textAlignLast: "center" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!allFieldsValid()} // Disable the button if not all fields are valid
            sx={{ width: 200, alignSelf: "center", marginTop: 2 }}
          >
            {t('formsLocale.submit')}
          </Button>
        </Box>

        {/* Divider and additional components */}
        <Divider sx={{ marginBottom: 5, marginTop: 5 }}></Divider>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <StartTestButton />
          <SkipPointButton />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          p={1}
          textAlign={"-webkit-center"}
        >
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item lg={12} xl={6} >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ paddingBottom: 20 }}
              >
                <HessLancasterGrid
                  isLeftEye={true}
                  isShowingValues={isShowingValues}
                  hessData={hessLancasterData}
                />
              </Box>
            </Grid>

            {/* Right Column */}
            <Grid item lg={12} xl={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ paddingBottom: 20 }}
              >
                <HessLancasterGrid
                  isLeftEye={false}
                  isShowingValues={isShowingValues}
                  hessData={hessLancasterData}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ textAlignLast: "center", pt: 2 }}>
          {/* Button to show or hide the values. When is clicked, change isShowingValues. Besides change variant between contained and outlined */}
          <Button
            variant={isShowingValues ? "outlined" : "contained"}
            color="primary"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={() => setIsShowingValues(!isShowingValues)}
          >
            {/* if isShowingValues is true, show the text "Hide Values", otherwise show the text "Show Values" */}
            {isShowingValues ? t('mainGridScreenLocale.hideValues') : t('mainGridScreenLocale.showValues')}
          </Button>

          <Button
            variant="contained"
            color="success"
            sx={{ width: 200, alignSelf: "center", marginTop: 2, mx: 2 }}
            onClick={startGeneratingPDF}
          >
            {t('pdfLocale.downloadPDF')}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={10000}
          onClose={handleSnackbarClose}
          sx={{
            marginLeft: 15,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={openTestParam}
        onClose={handleCloseTestParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.parametersTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionTestParamRef}
            tabIndex={-1}
          >
            <strong>Ojo:</strong> elegir ojo a medir.
            <ul>
              <li><em>Derecho:</em> evaluar la función oculomotora del ojo derecho en función de la visión binocular, con ojo izquierdo como fijador.</li>
              <li><em>Izquierdo:</em> evaluar la función oculomotora del ojo izquierdo en función de la visión binocular, con ojo derecho como fijador.</li>
            </ul>
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOptionParam}
        onClose={handleCloseOptionParam}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.optionsTitle')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionOptionParamRef}
            tabIndex={-1}
          >
            <strong>Tipo de estímulo:</strong>
            <ul>
              <li><em>Punto:</em> el estímulo que aparecerá en cada ojo será un punto. Útil para medir desviaciones verticales y horizontales.</li>
              <li><em>Vertical:</em> el estímulo que aparecerá en cada ojo será una línea vertical. Útil para medir desviaciones verticales, horizontales y ciclodesviaciones.</li>
            </ul>
            <strong>ENVIAR:</strong> una vez que la prueba se envía, no podremos hacer modificaciones sobre ella. Si por error hemos introducido algún dato incorrecto, podremos pulsar "botón B" del mando del visor, enviar la prueba correcta y que el paciente pulse de nuevo en "Comenzar"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionParam}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInstructions}
        onClose={handleCloseInstructions}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">
          {t('generalTestLocale.instructions')}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionInstructionsRef}
            tabIndex={-1}
          >
            Por favor, sitúe la línea/el punto de color blanco sobre el de color verde. Una vez superpuestos ambos, mantenga el botón <strong>trigger/gatillo</strong> presionado durante 3 segundos.
            <br /><br />
            <strong>Controles:</strong> Los botones, así como la imagen, hacen referencia al controlador derecho.
            <ul>
              <li><em>Botón A:</em> Repetir la última marca</li>
              <li><em>Botón B:</em> Volver al menú principal</li>
              <li><em>Botón trigger/gatillo:</em> Marcar los estímulos</li>
            </ul>
            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={FrontJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid item xs={6} >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img height={350} src={SideJoystick} alt="Logo" />;
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6" gutterBottom>
                    <strong>Controlador derecho</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInstructions}>{t('close')}</Button>
        </DialogActions>
      </Dialog>

      <HessLancasterPDF
        isGenerating={generatingPDF}
        stopGenerating={stopGeneratingPDF}
        userUID={currentUser.uid}
        patientUID={uid}
        hessData={hessLancasterData}
      >
      </HessLancasterPDF>

    </React.Fragment>
  );
};

export default HessLancasterForm;

