// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // cargar archivos de traducción desde el servidor
  .use(LanguageDetector) // detectar el idioma del navegador
  .use(initReactI18next) // pasar instance de i18n a react-i18next
  .init({
    fallbackLng: 'en', // idioma por defecto si el detectado no está disponible
    debug: true, // activar/desactivar los logs para depuración

    interpolation: {
      escapeValue: false, // react ya hace el escape por defecto
    },
  });

export default i18n;