import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';

import { ref, onValue } from 'firebase/database';
import { database } from '../../firebaseConfig';
import { AuthContext } from "../../providers/AuthContext";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  // Si el valor no es una cadena, ordena como antes
  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy], 'es', { sensitivity: 'base' });
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nombre',
  },
  {
    id: 'surname',
    numeric: false,
    disablePadding: false,
    label: 'Apellidos',
  },
  {
    id: 'birthDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de nacimiento',
  },
  {
    id: 'creationDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de creación',
  },
  {
    id: 'lastTestDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de última prueba',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/*
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
          */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, openAddPatient, openDeletePatient } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Lista de pacientes
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={ openDeletePatient } />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add button">
          <IconButton onClick={ openAddPatient }>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  openAddPatient: PropTypes.func.isRequired,
  openDeletePatient: PropTypes.func.isRequired,
};

export default function EnhancedTable({ onOpenAddPatient, onOpenDeletePatient }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {

    setIsLoading(true);

    const dataRef = ref(database, `patients/${currentUser.uid}`);
    // check if the user has any patients

    onValue(dataRef, (snapshot) => {
      const clientsData = snapshot.val();
      if (clientsData === null) {
        setIsLoading(false);
        return;
      }
      const mappedData = Object.keys(clientsData).map(clientId =>
        createData(clientId, clientsData[clientId])
      );
      setRows(mappedData);
      setIsLoading(false);
    });
  }, [currentUser]);

  function createData(id, data) {
    const name = data.name;
    const surname = data.surname;
    const birthDate = (data.birthDate == null || data.birthDate === "") ? "-" : data.birthDate;
    const creationDate = (data.creationDate == null || data.creationDate === "") ? "-" : data.creationDate;
    const lastTestDate = (data.lastTestDate == null || data.lastTestDate === "") ? "-" : data.lastTestDate;

    return {
      id,
      name,
      surname,
      birthDate,
      creationDate,
      lastTestDate
    };
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) { // no está seleccionado
      newSelected = [id];
    } 

    /* esto es para seleccionar varios. De momento vamos a hacer que solo se pueda seleccionar 1
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }*/
    setSelected(newSelected);
  };

  const handleClickRow = (event, id) => {
    navigate(`/patient/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const formatDate = (originalDate) => {
    if(originalDate === "-"){
      return "-";
    }
    // Separamos el año, mes y día
    let [año, mes, día] = originalDate.split("-");

    // Reorganizamos los valores en formato DD-MM-YYYY
    return `${día}-${mes}-${año}`;
  }

  const auxOpenDeletePatient = () => {
    const selectedRow = rows.find(row => row.id === selected[0]);
    onOpenDeletePatient(selected[0], selectedRow.name +" "+selectedRow.surname);
    setSelected([]);
  }

  return (
    <React.Fragment >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ width: '95%', maxWidth: 1500, mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} openAddPatient={onOpenAddPatient} openDeletePatient={auxOpenDeletePatient}/>
          <TableContainer>
            <Table
              sx={{ minWidth: 350 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClickRow(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Evita que el evento de clic se propague a la TableRow
                            handleClick(event, row.id); // Llama a la función específica del Checkbox
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        width="26%"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell width="26%">{row.surname}</TableCell>
                      <TableCell width="16%">{formatDate(row.birthDate)}</TableCell>
                      <TableCell width="16%">{formatDate(row.creationDate)}</TableCell>
                      <TableCell width="16%">{formatDate(row.lastTestDate)}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </React.Fragment>
  );
}
